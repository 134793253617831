import React, { useState } from "react";
import Footer from "../../components/Footer";
import useDetails from "../../hooks/useDetails";
import api from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Update password");
  const { data: details } = useDetails();

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const studentId = details?.student_user;

  const UserSchema = z
    .object({
      old_password: z.string(),
      password: z
        .string()
        .min(6, { message: "Password must be at least 6 characters" })
        .max(20, { message: "Password is too long" }),
      password2: z
        .string()
        .min(6, { message: "Password must be at least 6 characters" }),
    })
    .refine((data) => data.password === data.password2, {
      message: "Passwords does not match",
      path: ["password2"], // path of error
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(UserSchema),
  });

  const handleChangePassword = async (data) => {
    setLoading(true);
    setBtnText("Loading...");
    try {
      const res = await api.put(
        `/password/change-password-id/${studentId}`,
        data
      );
      console.log(res);
      toast.success("Password changed!", successProp);
      setLoading(false);
      reset();
      setBtnText("Update password");
    } catch ({ response }) {
      if (
        response.status === 400 &&
        Object.keys(response.data).includes("old_password")
      ) {
        setLoading(false);
        setBtnText("Failed, try again");
        return toast.error("Old password not correct", errorProp);
      }
      toast.error("Error occured, try again", errorProp);
      setLoading(false);
      setBtnText("Failed, try again");
    }
  };
  return (
    <>
      {details && (
        <div class="account-body">
          <div class="container">
            <div class="row vh-100 ">
              <div class="col-12 align-self-center">
                <div class="auth-page">
                  <div class="card auth-card shadow-lg">
                    <div class="card-body">
                      <div class="px-3">
                        <div class="text-center auth-logo-text">
                          <h4 class="mt-0 mb-3 mt-5">Change Password</h4>
                        </div>

                        <form
                          class="form-horizontal auth-form my-4"
                          onSubmit={handleSubmit(handleChangePassword)}
                        >
                          <div class="form-group">
                            <label for="old_password">Old Password</label>
                            <div class="input-group mb-1">
                              <span class="auth-form-icon">
                                <i class="dripicons-lock"></i>
                              </span>
                              <input
                                type="password"
                                class="form-control"
                                placeholder="Enter old password"
                                {...register("old_password")}
                              />
                            </div>
                            {errors && (
                              <span className="text-danger">
                                {errors.old_password?.message}
                              </span>
                            )}
                          </div>
                          <div class="form-group">
                            <label for="password">New Password</label>
                            <div class="input-group mb-1">
                              <span class="auth-form-icon">
                                <i class="dripicons-lock"></i>
                              </span>
                              <input
                                type="password"
                                class="form-control"
                                placeholder="Enter new password"
                                {...register("password")}
                              />
                            </div>
                            {errors && (
                              <span className="text-danger">
                                {errors.password?.message}
                              </span>
                            )}
                          </div>
                          <div class="form-group">
                            <label for="password2">Confirm Password</label>
                            <div class="input-group mb-1">
                              <span class="auth-form-icon">
                                <i class="dripicons-lock"></i>
                              </span>
                              <input
                                type="password"
                                class="form-control"
                                placeholder="Enter new password again"
                                {...register("password2")}
                              />
                            </div>
                            {errors && (
                              <span className="text-danger">
                                {errors.password2?.message}
                              </span>
                            )}
                          </div>
                          <div class="form-group mb-0 row">
                            <div class="col-12 mt-2">
                              <button
                                class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                              >
                                {btnText}{" "}
                                <i class="fas fa-sign-in-alt ml-1"></i>
                              </button>
                            </div>
                          </div>
                          <ToastContainer />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ChangePassword;
